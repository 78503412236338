














































import { Component, Prop } from 'vue-property-decorator';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { mixins } from 'vue-class-component';
import uniqid from 'uniqid';
import draggable from 'vuedraggable';

  @Component({
    components: {
      draggable,
    },
  })
export default class ConfirmPopup extends mixins() {

@Prop({ type: String, default: 'Are you sure, you want to cancel and lose all information?' }) message!: boolean;
}

